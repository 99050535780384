<template>
	<div>
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('goBack')"
							class="arrow_white">
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{ text_title }}</h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }} &gt; {{ item_board_config.board_name }}</span>
					</div>
				</div>
			</div>
			<div class="save mt-10">
				<button
					@click="doPost"
					class="btn_save"
					:disabled="is_disabled"
				>{{  $language.common.complete }}</button>
			</div>
		</div>
		<!-- //고정해더 -->
		<!-- 글쓰기 영역 -->
		<div class="write_wrap pt-70 pb-50">
			<div class="container">
				<div
					v-if="!is_notice"
					@click="onBoard"
					class="article_write_form_subject"
				>
					<span>{{ text_board_name }}</span>
					<button
					><v-icon>mdi-chevron-down</v-icon></button>
				</div>
				<div
					v-show="is_notice"
					class="article_write_form"
				>
					<!-- 댓글 작성 -->
					<div class="article_write_option_check">
						<span class="option_name">{{  $language.confirm.write_comment.title }}</span>
						<em class="option_desc">{{  $language.confirm.write_comment.choice_comment }}</em>
						<div class="option_check-toggle">
							<p class="toggle-group">
								<!-- TODO : 수신 허용시 .on 클래스 추가 -->
								<label><span class="btn-toggle" :class="{ on: item_bbs.is_comment}"><input v-model="item_bbs.is_comment" type="checkbox" value="true" style="visibility: hidden"/><em class="hide">{{  $language.common.notify_notice }}</em></span></label>
							</p>
						</div>
					</div>
					<!-- //댓글 작성 -->
					<!-- 제목입력 -->
					<div class="article_write_form_subject">
						<input
							v-model="item_bbs.title"
							:placeholder="$language.common.title_require"
							class="input_text"
							maxlength="50"
						/>
					</div>
					<!-- //제목입력 -->
				</div>

				<!-- 미디어 영역 -->
				<div class="article_media_list_wrap">
					<div class="media_wrap">
						<draggable
							v-model="files"
							handle=".handle"
						>
							<div
								v-for="(file, index) in files"
								:key="'files_' + index"
								class="photo_upload_item"
							>
								<div
									v-if="file.type == 'CA01000001'"
									class="media_content_item"
								>
									<img
										:src="file.src"
										style="width: 100%"
									/>
									<button class="item_close">
										<i
											@click="removeFile(index)"
											class="icon-close"
										></i>
									</button>
								</div>
								<div
									v-else-if="file.type == 'CA01000002'"
									class="media_content_item m_video"
								>
									<video
										width="100%"
										:ref="'video_' + index"
										@loadedmetadata="onLoadMetadata($event, index)"
									>
										<source
											:src="file.src"
											type="video/webm"
										>
									</video>
									<button class="item_close">
										<i
											@click="removeFile(index)"
											class="icon-close"
										></i>
									</button>
								</div>
								<div
									v-else-if="file.type == 'CA01000003'"
									class="media_content_item m_music"
								>
									<audio
										:src="file.src"
									></audio>
									<button class="item_close">
										<i
											@click="removeFile(index)"
											class="icon-close"
										></i>
									</button>
								</div>
								<div class="media_content_name">
									<p>{{  file.file_name }}</p>
								</div>
								<div class="media_content_drag handle">
									<div class="drag_bar">
										<img :src="require('@/assets/image/btn_order.png')" alt="">
									</div>
								</div>
							</div>
						</draggable>
					</div>
				</div>
				<!-- //미디어 영역 -->

				<div class="one_editor">
					<div class="ct_text_editor">
						<!-- 글쓰기영역 -->
						<mafia_vote
							v-if="is_vote"
							:vote="item_bbs.vote"
							:cartel="item_cartel"
							:bbs="item_bbs"
							:user="user"

							@cancel="removeVote"
							@change="setVote"
						></mafia_vote>

						<div class="ct_container">
							<div class="ct_textarea">
								<!-- 내용을 입력하세요 -->
								<textarea
									v-model="item_bbs.content"
									:placeholder="$language.common.content_require"
									ref="content"

									@input="reSize"
									rows="15"

								></textarea>
							</div>
						</div>

						<!-- //글쓰기영역 -->
						<div
							v-if="!is_cheer"
							class="ct_button_area"
							style="z-index: 99"
						>
							<div class="container">
								<div class="toolbar_list_area">
									<ul
										class="toolbar_list"
									>
										<li>
											<button @click="toFile"><label><span class="toolbar_icon tb_photo">{{  $language.common.picture }}</span><input-file-new accept="image/*" multiple @change="setFile('image', $event)" ref="file_image" /></label></button>
										</li>
										<li>
											<button
												@click="toFile"><label><span class="toolbar_icon tb_viedo" >{{  $language.common.video }}</span><input type="file" accept="video/*" multiple @change="setVideoFile('video', $event)" ref="file_video" class="hide" /></label></button>
										</li>
										<li>
											<button @click="toFile"><label><span class="toolbar_icon tb_sound" >{{  $language.common.sound }}</span><input-file-new accept="audio/*" multiple @change="setFile('audio', $event)" ref="file_sound"/></label></button>
										</li>
										<li>
											<button
												@click="resetVote"
											><span class="toolbar_icon tb_vote">{{  $language.common.voting }}</span></button>
										</li>
									</ul>

									<!-- 첨부파일 변경 이전-->
									<ul
										v-if="false"
										class="toolbar_list"
									>
										<li>
											<button @click="toFile"><label><span class="toolbar_icon tb_photo">{{  $language.common.picture }} <!-- 사진 --> </span><input-file-new accept="image/*" multiple @change="setFile('image', $event)" ref="file_image" /></label></button>
										</li>
										<li>
											<button @click="toFile"><label><span class="toolbar_icon tb_viedo" >{{  $language.common.video }}  <!-- 비디오 --> </span><input-file-new accept="video/*" multiple @change="setFile('video', $event)" ref="file_video"/></label></button>
										</li>
										<li>
											<button @click="toFile"><label><span class="toolbar_icon tb_sound" >{{  $language.common.sound }}  <!-- 사운드 --> </span><input-file-new accept="audio/*" multiple @change="setFile('audio', $event)" ref="file_sound"/></label></button>
										</li>
										<li>
											<button
												@click="resetVote"
											><span class="toolbar_icon tb_vote">{{  $language.common.voting }}  <!-- 투표 --> </span></button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			v-if="is_subscribe"
			class="size-px-16 bg-gray-light pt-10 pb-50"
		>
			<div
				class="pa-20 bg-white"
			>
				<div>{{ $language.subscribe.title_subscribe_plan_choice}} <!-- 적용 플랜 선택 --></div>
				<div class="mt-10 size-px-13 color-gray">{{ $language.subscribe.title_subscribe_plan_choice}} <!-- 선택된 플랜 등급이상 구독 멤버에 한해 이글을 볼 수 있습니다. --></div>

				<div class="mt-10">
					<ul>
						<li
							v-for="(payment, p_index) in items_payment"
							:key="'payment_' + p_index"

							@click="setPlan(payment)"
							class="mt-10"
						>
							<v-icon
								v-if="payment.is_check"
								class="color-blue"
							>mdi-checkbox-marked-circle</v-icon>
							<v-icon
								v-else
							>mdi-checkbox-blank-circle-outline</v-icon>
							<span class="size-px-14 ml-10">{{ payment.subscrp_plan_name}}</span>
						</li>
					</ul>
				</div>
			</div>
			<div
				class="pa-20 mt-10 bg-white"
			>
				<div class="justify-space-between items-center" @click="onSpecial">
					{{ $language.subscribe.title_subscribe_advanced }} <!-- 고급설정 -->
					<v-icon>mdi-chevron-right</v-icon>
				</div>
				<div class="mt-20 justify-space-between" @click="onList">
					{{ $language.subscribe.title_subscribe_load_text }} <!-- 글 불러오기 -->
					<v-icon>mdi-chevron-right</v-icon>
				</div>
			</div>
		</div>
		<popup_select
			v-if="is_select"
			@cancel="is_select = false"
		>
			<template
				v-slot:title
			>{{  $language.notice.choice }}</template>
			<template
				v-slot:list
			>
				<template
					v-for="(item, index) in list_board_config"
				>
					<li
						v-if="item.board_authority.reg_fg == 'Y'"
						:key="'item_' + index"
						style="padding: 10px 0"
						@click="setBoard(item)"
					>{{ item.board_name }}</li>
				</template>
			</template>
		</popup_select>


		<popup_confirm
			v-if="is_popup"
			@cancel="is_popup = false"
			@click="setVideo"
		>
			<template
				v-slot:title
			>{{  $language.common.video }}</template>

			<template
				v-slot:main-txt
			>{{ $language.interested.youtube_require }}</template>

			<template
				v-slot:sub-txt
			>
				<div
					v-for="i in video_size"
					:key="'i_' + i"
				>
					<input
						v-model="video[video_size - i]"
						type="text"
						style="border: 1px solid #ddd; padding: 10px"
						class="mt-10"
						placeholder="https://"
					/>
					<v-icon
						v-if="i == video_size"
						@click="setVideoSize(true, i)"
						class="ml-10 mt-5"
					>mdi-plus-circle-outline</v-icon>
					<v-icon
						v-else
						@click="setVideoSize(false, i)"
						class="ml-10 mt-5"
					>mdi-minus-circle-outline</v-icon>
				</div>
			</template>
		</popup_confirm>

		<PopupLayer
			v-if="is_on_special"
		>
			<template
				v-slot:body
			>
				<div class="bg-white  radius-20 overflow-hidden">
					<div class="pa-20">
						<h3 class="pop_tit pop_tit_logo text-center under-line mt-30 pb-30">{{ $language.subscribe.title_subscribe_advanced }} <!-- 고급설정 --></h3>
						<div
							class=" mt-10 bg-white"
						>
							<div>
								<div
									class="justify-space-between items-center"
									@click="$set(item_setting, 'bdy_release_fg', item_setting.bdy_release_fg == 'Y' ? 'N' : 'Y')"
								>
									<h4 class="size-px-16">{{ $language.subscribe.title_subscribe_open }} <!-- 본문 공개 --></h4>
									<p class="toggle-group">
										<!-- TODO : 수신 허용시 .on 클래스 추가 -->
										<span
											class="btn-toggle"
											:class="{ on: item_setting.bdy_release_fg == 'Y' }"
										><em class="hide">{{  $language.common.dark_mode }}</em></span>
									</p>
								</div>
								<div class="mt-10 size-px-13 color-gray">{{ $language.subscribe.txt_subscribe_open }} <!-- 이 게시물의 본문을 숨길 경우 권한이 없는 멤버는 본문을 볼 수가 없습니다. --></div>
							</div>
							<div class="mt-20">
								<div
									class="justify-space-between items-center"
									@click="$set(item_setting, 'comment_release_fg', item_setting.comment_release_fg == 'Y' ? 'N' : 'Y')"
								>
									<h4 class="size-px-16">{{ $language.subscribe.title_subscribe_open_comment }} <!-- 댓글 공개 --></h4>
									<p class="toggle-group">
										<!-- TODO : 수신 허용시 .on 클래스 추가 -->
										<span
											class="btn-toggle"
											:class="{ on: item_setting.comment_release_fg == 'Y' }"
										><em class="hide">{{  $language.common.dark_mode }}</em></span>
									</p>
								</div>
								<div class="mt-10 size-px-13 color-gray">{{ $language.subscribe.txt_subscribe_open_comment }} <!-- 이 게시물의 댓글을 숨길 경우 권한이 없는 멤버는 댓글을 볼 수가 없습니다. --></div>
							</div>
						</div>
					</div>
					<div
						class="btn_wrap justify-space-between"
					>
						<button
							class=" btn_fill_gray"
							@click="resetSpecial"
						>{{ $language.common.close }} <!-- 닫기 --></button>
						<button
							class=" btn_fill_blue"
							@click="setSpecial"
						>{{  $language.point_result.btn_confirm }} <!-- 확인 --></button>
					</div>
				</div>
			</template>
		</PopupLayer>

		<CartelBoardCopy
			v-if="is_on_list"
			:user="user"
			:cartel_info="item_cartel"
			:board_info="item_board_config"

			@cancel="offList"
			@click="setCopyItem"
		>
		</CartelBoardCopy>
	</div>
</template>

<style>
	.m_video {	display: flex; flex-direction: column; justify-content: center; border: 1px solid #ddd; background-color: var(--bg-Gray02); border-radius: 10px; overflow: hidden}

	.article_write_form_subject { font-size: 16px; display: flex; justify-content: space-between; padding: 10px}

	.ct_textarea textarea { height: none; min-height: none;}

</style>

<script>

import popup_confirm from "@/view/Layout/PopupConfirm";
import popup_select from "@/view/Layout/PopupSelect";
import draggable from 'vuedraggable'
import mafia_vote from '@/view/Cartel/mafiaVote'
import PopupLayer from "@/view/Layout/PopupLayer";
import CartelBoardCopy from "@/view/CartelBoard/CartelBaordCopy";
import InputFileNew from "@/components/InputFileNew";

export default {
	name: 'mafia1271'
	, components: {
		InputFileNew,
		CartelBoardCopy, PopupLayer, popup_confirm, popup_select, draggable, mafia_vote}
	, props:['user']
	, data: function(){
		return {
			program: {
				name: ''
				, title: ''
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
			}
			, item_cartel: {}
			, item_board_config: {
				id: ''
			}
			, item_bbs: {
				code: this.$route.params.code
				, user_id: this.user.id
				, nick: this.user.nick
				, video:[]
				, vote: {
					title: ''
					, items: [
						{ img_src: '', title: ''}
					]
					, date: this.$date.getToday('-')
					, time: ['am', '12', '00']
					, is_multiple: true
				}
				, title: ''
				, content: ''
				, is_comment: true
				, date: ''
				, bdy_release_fg: 'Y'
				, comment_release_fg: 'Y'
			}
			, files: []
			, video: []
			, video_size: 1
			, items_board_config: []
			, is_select: false
			, bbs_id: this.$route.params.bbs_id
			, is_popup: false
			, file_max: 8
			, is_vote: false
			, vote_fg: false
			, post_file_list: []
			, upload_files: []
			, items_payment: []
			, is_on_special: false
			, is_on_list: false
			, items_list: []
			, item_copy: {}
			, items_video: []
			, item_aws: {
				stremg_video_file_list: []
			}
			, item_setting: {
				bdy_release_fg: 'Y'
				, comment_release_fg: 'Y'
			}
		}
	}
	, computed: {
		is_disabled: function(){
			let t = false
			if(!this.item_bbs.content || this.item_bbs.content.trim() == ''){
				t = true
			}
			if(this.item_board_config.board_type_code == 'CA00700001' && (!this.item_bbs.title || this.item_bbs.title == '')){
				t = true
			}

			if(this.item_board_config.board_type_code == 'CA00700005' && this.post_subscrp_plan_list.length == 0){
				t = true
			}
			return t
		}
		, is_cheer: function(){
			let t = false
			if(this.item_board_config.board_type_code == 'CA00700002'){
				t = true
			}
			return t
		}
		, text_title: function(){
			let t = this.$language.word.write

			if(this.$route.params.bbs_id){
				t = this.$language.word.modify
			}

			return t
		}
		, text_board_name: function(){
			let t = ''

			t =  this.item_board_config.board_name

			return t
		}
		, is_notice: function(){
			let t = false

			if(this.item_board_config.board_type_code == 'CA00700001'){
				t = true
			}

			return t
		}
		, vote_info: function(){
			if(this.vote_fg){
				return {
					vote_info: ''
					, vote_title: this.item_bbs.vote.title
					// , vote_startdt: this.item_bbs.vote.date + ' ' + this.item_bbs.vote.time.join(':')
					, vote_enddate: this.makeEnddate()
					, vote_type_code: this.item_bbs.vote.is_multiple ? 'CA01400002' : 'CA01400001'
				}
			}else{
				return {

				}
			}

		}
		, is_subscribe: function(){
			let t = false
			if(this.item_board_config.board_type_code == 'CA00700005'){
				t = true
			}
			return t
		}
		, post_subscrp_plan_list: function(){
			return this.items_payment.filter( (item) => {
				if(item.is_check){
					return {
						subscrp_plan_number: item.subscrp_plan_number
					}
				}
			})
		}
		, list_board_config: function(){
			return this.items_board_config.filter( (item) => {

				if(this.is_manager){
					if(item.board_type_code != "CA00700006" && item.board_type_code != "CA00700007"){
						return item
					}
				}else{
					if(item.board_type_code == 'CA00700002' || item.board_type_code == 'CA00700003' || item.board_type_code == 'CA00700004'){
						if(item.board_authority.reg_fg == 'Y'){
							return item
						}
					}
				}
			})
		}
		, is_manager: function(){
			let t = false
			if(this.item_cartel.cartl_member_grade_chg_info.chg_cartl_member_div_code == 'CA02500001'){
				t = true
			}
			return t
		}
	}
	,methods: {
		getBoardConfig: async function(){

			try {

				if(!this.$route.params.b_id){
					if(this.items_board_config[0].board_type_code == 'CA00700001'){
						this.items_board_config.splice(0, 1)
					}
					this.$set(this, 'item_board_config', this.items_board_config[0])
				}else{

					for(let b of this.items_board_config){
						if(b.board_number == this.$route.params.b_id){
							this.item_board_config = b
							break
						}
					}
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postFiles: async function (){
			return true
		}
		, postFile: async function(appendix_file_div_code, file){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_file
					, data: {
						member_number: this.user.member_number
						, file_upload_code: 'CM00700015'
						, appendix_file_div_code: appendix_file_div_code
						, appendix_file_name: file
					}
					, multipart: true
					, type: true
				})

				if(result.success){
					return result.data.file_url
				}else{
					throw result.message
				}

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}
		}

		, postNotice: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_notice
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, post_title: this.item_bbs.title
							, post_contents: this.item_bbs.content
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							, post_file_list: this.post_file_list
							, vote_fg: this.vote_fg ? 'Y' : 'N'
							, vote_info: this.vote_info
							, vote_list: this.item_bbs.vote.items
							, comment_writing_fg: this.item_bbs.is_comment ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						this.item_aws = result.data
						await this.postAws()
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postCheer: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cheer
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.item_board_config.board_number
							, post_contents: this.item_bbs.content
						}
						, type: true
					})

					if(result.success){
						this.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx, code: this.item_board_config.board_type_code, b_id: this.item_board_config.board_number }})
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postBbs: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_bbs
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.item_board_config.board_number
							, post_contents: this.item_bbs.content
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							, post_file_list: this.post_file_list
							, vote_fg: this.vote_fg ? 'Y' : 'N'
							, vote_info: this.vote_info
							, vote_list: this.item_bbs.vote.items
							, comment_writing_fg: this.item_bbs.is_comment ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						this.item_aws = result.data
						await this.postAws()
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postSubscribe: async function(){
			try {
				this.$bus.$emit('on', true)

				if (await this.postFiles()) {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_subscribe_board
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.item_board_config.board_number
							, post_contents: this.item_bbs.content
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							, post_file_list: this.post_file_list
							, vote_fg: this.vote_fg ? 'Y' : 'N'
							, vote_info: this.vote_info
							, vote_list: this.item_bbs.vote.items
							, bdy_release_fg: this.item_bbs.bdy_release_fg
							, comment_release_fg: this.item_bbs.comment_release_fg
							, post_subscrp_plan_list: this.post_subscrp_plan_list
						}
						, type: true
					})

					if (result.success) {
						this.item_aws = result.data
						await this.postAws()

					} else {
						throw result.message
					}
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('on', false)
				this.$bus.$emit('notify', {type: 'error', message: e})
			}
		}
		, getBoardList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_menu
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if (result.success) {
					this.items_board_config = result.data.board_list
					this.$bus.$emit('setBoardConfigSort', this.items_board_config)
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onBoard: function(){
			this.is_select = true
		}
		, setBoard: function(item){
			if(item.board_authority.reg_fg != 'Y'){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.auth.no_authority_write_article})
			} else{
				this.is_select = false
				this.item_board_config = item
				this.item_bbs.code = item.code

				this.$bus.$emit('to', { name: 'mafia1271', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number}})
			}
		}
		, removeFile: function(index){
			this.files.splice(index, 1)
			this.upload_files.splice(index, 1)
			this.post_file_list.splice(index, 1)
		}
		, removeVideo: function(index){
			this.video.splice(index, 1)
		}
		, getBbs: async function(){
			try{
				if(!this.bbs_id){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.bbs_id
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					if(this.item_bbs.files){
						this.files = this.item_bbs.files
					}
					if(this.item_bbs.video){
						this.video = this.item_bbs.video
						this.video_size = this.video.length
					}
					if(this.item_bbs.vote){
						this.is_vote = true
					}

					this.item_bbs.content = this.item_bbs.post_contents

					this.item_setting.bdy_release_fg = this.item_bbs.bdy_release_fg
					this.item_setting.comment_release_fg = this.item_bbs.comment_release_fg
					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPopup: function(){
			this.is_popup = true
		}
		, setVideoFile: function(type){
			let file_count = this.files.length + 1
			if(file_count > this.file_max){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_file_limit})
				return false
			}

			let post_appendix_file_div_code = ''
			// console.log(this.$refs['file_' + type].files)
			let file = this.$refs['file_' + type].files[0]
			file.index = file_count

			this.items_video.push(file)
			console.log(this.items_video)
			// console.log(file, e)

			const videourl = URL.createObjectURL(file);
			if(type.indexOf('video') > -1){
				post_appendix_file_div_code = 'CA01000002'

			}else if(type.indexOf('audio') > -1){
				post_appendix_file_div_code = 'CA01000003'
			}else{
				post_appendix_file_div_code = 'CA01000001'
			}
			this.upload_files.push({ type: type, src: videourl, file_name: file.name})
			this.files.push({ type: post_appendix_file_div_code, src: videourl, file_name: file.name})

			let d = file.name.split('.')
			let t = d[d.length - 1]

			let contents_meta_data = {

				contents_type: t
				, contents_original_resolu_width: 0
				, contents_original_resolu_height: 0
				, contents_original_rolling_direction: 0
				,contents_length_seconds: '0'
			}

			file.contents_meta_data = contents_meta_data

			this.post_file_list.push({
				post_appendix_file_div_code: post_appendix_file_div_code
				, post_file_url: file.name
				, post_appendix_file_indexnumber: file_count
				, index: file_count
				, contents_meta_data: contents_meta_data
			})
		}
		, setFile: function(type, e){

			let file_count = this.files.length + 1
			if(file_count > this.file_max){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_file_limit})
				return false
			}

			let post_appendix_file_div_code = ''
			let file = e

			if(type.indexOf('video') > -1){
				post_appendix_file_div_code = 'CA01000002'
			}else if(type.indexOf('audio') > -1){
				post_appendix_file_div_code = 'CA01000003'
			}else{
				post_appendix_file_div_code = 'CA01000001'
			}
			this.upload_files.unshift({ type: type, src: file})
			this.files.unshift({ type: post_appendix_file_div_code, src: file})

			let d = file.split('.')
			let t = d[d.length - 1]

			let contents_meta_data = {

				contents_type: t
				, contents_original_resolu_width: 0
				, contents_original_resolu_height: 0
				, contents_original_rolling_direction: 0
				,contents_length_seconds: '0'
			}

			this.post_file_list.unshift({
				post_appendix_file_div_code: post_appendix_file_div_code
				, post_file_url: file
				, post_appendix_file_indexnumber: file_count
				, contents_meta_data: contents_meta_data
			})
		}
		, setVideo: function(e){

			for(let video of e.target.files){
				const reader = new FileReader()
				let self = this
				reader.onload = function(e){
					self.video.unshift(e.target.result)
				}

				reader.readAsDataURL(video)
			}
		}
		, handleClick(event, ref) {
			this.$refs[ref][event]();
		}
		, handleSearch(e, platform) {
			if (platform === "youtube") this.youtubeLink = e.target.value;
			else this.vimeoLink = e.target.value;
		}
		, setVideoSize: function(type, index){
			if(this.video_size > 5){
				return false
			}
			if(type){
				this.video_size++
			}else{
				this.video.splice(this.video_size - index, 1)
				this.video_size--
			}
		}
		, setVote: function(e){
			this.$set(this.item_bbs, 'vote', e)
		}
		, resetVote: function(){
			if(!this.item_bbs.vote){

				this.item_bbs.vote = {
					title: ''
					, items: [
						{ img_src: '', title: ''}
						, { img_src: '', title: ''}
					]
					, date: this.$date.getToday('-')
					, time: []
					, is_multiple: true
				}
			}

			this.vote_fg = true
			this.is_vote = true
		}
		, removeVote: function(){
			this.item_bbs.vote = null
			this.is_vote = false
			this.vote_fg = false
		}

		, reSize: function(e){
			e.target.style.height = 'auto'
			e.target.style.height = e.target.scrollHeight + 'px'
		}
		, controlVideo: function(index){

			this.$refs['video_' + index][0].play()
		}
		, doPost: function(){

			if(this.item_board_config.board_type_code == 'CA00700001'){
				this.postNotice()
			}else if(this.item_board_config.board_type_code == 'CA00700002'){
				this.postCheer()
			}else if(this.item_board_config.board_type_code == 'CA00700005') {
				this.postSubscribe()
			}else{
				this.postBbs()
			}
		}
		, makeEnddate: function(){
			return this.item_bbs.vote.date + ' ' + this.item_bbs.vote.time.join(':')
		}
		, toFile: function(){
			this.$bus.$emit('not_pause')
		}
		, getPlan: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.items_payment = result.data.subscrp_plan_list
					// this.items = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setPlan: function(item){
			this.items_payment.filter( (payment) => {
				if(item.subscrp_plan_number == payment.subscrp_plan_number){
					this.$set(payment, 'is_check', true)
				}else{
					this.$set(payment, 'is_check', false)
				}
			})
		}
		, onSpecial: function(){
			this.is_on_special = true
		}
		, offSpecial: function(){
			this.is_on_special = false
		}
		, setSpecial: function(){
			this.item_bbs.bdy_release_fg = this.item_setting.bdy_release_fg
			this.item_bbs.comment_release_fg = this.item_setting.comment_release_fg
			this.offSpecial()
		}
		, resetSpecial: function(){
			this.item_setting.bdy_release_fg = this.item_bbs.bdy_release_fg
			this.item_setting.comment_release_fg = this.item_bbs.comment_release_fg
			this.offSpecial()
		}
		, onList: function(){
			this.is_on_list = true
		}
		, offList: function (){
			this.is_on_list = false
		}
		, setCopyItem: function(item){
			console.log('setCopyItem', item)
			this.item_copy = item
			this.item_bbs.content = item.contents

			if(item.post_file_list.length > 0){
				for(let [key, val] of item.post_file_list.entries()){
					console.log(key)
					this.files.push({
						name: val.post_file_name
						, src: val.file_url
						, type: val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
					})
					val.name = val.post_file_name
					val.src = val.file_url
					val.type = val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
					this.upload_files.push(val)
				}
			}
			document.body.scrollTop = 0

			this.offList()
		}
		, onLoadMetadata: function(e, index){
			console.log('onLoadMetadata', e.target.videoWidth, e.target.videoHeight, e)
			let width = e.target.videoWidth
			let height = e.target.videoHeight
			let duration = e.target.duration
			let name = this.upload_files[index].file_name
			let d = name.split('.')
			let t = d[d.length - 1]
			this.$set(this.post_file_list[index], 'contents_meta_data', {
				contents_type: t // "콘텐츠타입", ->required   //png, mp4, mp3 등(현재는 미사용)
				, contents_original_resolu_width: width // ": 콘텐츠원본해상도너비, ->required   //동영상 또는 이미지의 너비(정수), 회전이 없는 컨텐츠일 경우 0
				, contents_original_resolu_height: height // ": 콘텐츠원본해상도높이, ->required   //동영상 또는 이미지의 높이(정수), 회전이 없는 컨텐츠일 경우 0
				, contents_original_rolling_direction: height > width ? 9 : 0 // ": 콘텐츠원본회전방향, ->required   //0(회전이 없는 컨텐츠), 1(회전 없음), 3(180도 회전), 6(시계 방향으로 90도 회전), 8(반시계 방향으로 90도 회전)
				, contents_length_seconds: duration + ''// ": 콘텐츠길이초 ->required
			})

			console.log('onLoadMetadata', this.post_file_list)
		}
		, postAws: async function(){
			try{
				this.$bus.$emit('on', true)
				let files = this.items_video;
				console.log('files', files)
				for(let i = 0; i < files.length; i++){
					let file = files[i]
					let item = this.item_aws.stremg_video_file_list[i]

					const result = await this.$Request({
						method: 'put'
						, url: item.stremg_video_file_url
						, header: {
							// 올리고자하는 동영상의 확장자로 설정 - test-video 파일의 경우 mp4
							"Content-Type": "video/" + this.post_file_list[i].contents_meta_data.contents_type,
							// "SHTP-02-001 숏플 게시물 등록 요청" - stremg_video_upload_header {key:value} 로 리퀘스트 헤더에 추가
							"x-amz-tagging": item.stremg_video_upload_header['X-Amz-Tagging'][0]
							, "amz-sdk-request" : ["attempt=1; max=3"]
						}
						, data: file
						, type: true
						, is_data: true
						, isResult: true
					})

					if(result.success){
						this.$bus.$emit('notify', { type: 'success', message: this.$language.shorts.txt_shorts_success2})
					}else{
						throw result.message
					}
				}
				if(this.item_board_config.board_type_code == 'CA00700001') {
					this.$emit('to', {name: 'mafia127',
						params: {
							idx: this.$route.params.idx,
							code: this.$route.params.code,
							b_id: this.$route.params.b_id
						}
					})
				}else{
					this.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx, code: this.item_board_config.board_type_code, b_id: this.item_board_config.board_number }})
				}

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		await this.getBoardList()
		await this.getBoardConfig()
		await this.getPlan()
		if(this.bbs_id){
			await this.getBbs()
		}
		if(this.list_board_config.length < 1){
			this.$bus.$emit('notify', { type: 'error', message: '작성가능한 게시판이 없습니다.'})
			this.$emit('goBack')
		}
	}


}
</script>