<template>
	<div class="position-fixed-full " style="z-index: 999999999">
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('cancel')"
							class="arrow_white">
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{ text_title }}</h2>
						<span class="page_subtit">{{  cartel_info.cartl_name }} &gt; {{ board_info.board_name }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="write_wrap bg-white pt-70 pb-50 full-height flex-column">
			<div class="">
				<div
					@click="onBoard"
					class="article_write_form_subject"
				>
					<span>{{ text_board_name }}</span>
					<button
					><v-icon>mdi-chevron-down</v-icon></button>
				</div>
			</div>

			<div class="full-height overflow-y-auto bg-gray-light">
				<template
					v-if="list_bbs.length > 0"
				>
					<div
						v-for="(item, index) in list_bbs"
						:key="'item_' + index"
						class="mb-10 pb-10 bg-white"
					>
						<div class="">
							<article class="">
								<!-- 게시글 작성자 -->
								<div class="justify-space-between pa-20 items-center under-line">
									<div class="justify-space-between items-center">
										<span class="img-box-30 radius-100 mr-10">
											<img
												:src="$request.upload_url(item.profle_nft_card_img_url)"
												@error="$bus.$emit('onErrorImage', $event)"
												class="object-cover"
											/>
										</span>
										<span class="size-px-16">{{ item.post_member_nickname }}</span>
									</div>
									<div class="">
										<button
											@click="onMore(item)"
											class="btn_s btn_outline_blue"
										>불러오기</button>
									</div>
								</div>

								<!-- //게시글 작성자 -->
								<!-- 게시글 메인 영역 -->
								<div
									v-if="!item.is_block"
									class="post_main_view "
								>
									<!-- 비주얼 영역 -->
									<div class="photo_view">
										<!-- 슬라이드 -->

										<div class="photo_collage_slide">
											<template
												v-if="item.post_file_list && item.post_file_list.length == 1"
											>
												<div
													v-for="(file, f_index) in item.post_file_list"
													:key="'item_' + index + '_file_' + f_index"
													class="text-center "
													:class="{blur: item.is_blur}"
												>
													<img
														v-if="item.is_blur"
														:src="require('@/assets/image/ico_lock.png')"
														class="position-absolute "
														style="width: 40px; left: calc(50% - 40px); top: calc(50% - 40px); z-index: 999"
													>
													<img
														v-if="file.is_image"
														:src="$request.upload_url(file.file_url)"
														@error="$bus.$emit('onErrorImageSample', $event)"
														class="object-cover"
													/>
													<video
														v-if="file.is_video"
														width="100%"
														controls
													>
														<source
															:src="file.file_url"
														>
													</video>
													<div
														v-if="file.is_audio"
														class="text_view"
														style="position: static !important; padding: 0; width: 100%"
													>
														<figure class="audio_wrap">
															<div class="audio_bg">
															<span class="hide">
																{{ $language.common.soundBackgroundImg }}
															</span>
															</div>
															<div class="bg-gray">
																<video
																	width="100%"
																	controls
																	height="40px"
																>
																	<source
																		:src="file.file_url"
																	>
																</video>
															</div>
														</figure>
													</div>
												</div>
											</template>
											<carousel
												v-else
												:perPage="1"
											>
												<slide
													v-for="(file, f_index) in item.post_file_list"
													:key="'item_' + index + '_file_' + f_index"
													class="photo_collage_view flex-column justify-center items-center"
													:class="{blur: item.is_blur}"
												>
													<img
														v-if="file.is_image"
														:src="$request.upload_url(file.file_url)"
														@error="$bus.$emit('onErrorImageSample', $event)"
													/>
													<video
														v-if="file.is_video"
														width="100%"
														controls
													>
														<source
															:src="file.file_url"
														>
													</video>
													<div
														v-if="file.is_audio"
														class="text_view"
														style="position: static !important; padding: 0; width: 100%"
													>
														<figure class="audio_wrap">
															<div class="audio_bg">
															<span class="hide">
																{{ $language.common.soundBackgroundImg }}
															</span>
															</div>
															<div class="bg-gray">
																<video
																	width="100%"
																	controls
																	height="40px"
																>
																	<source
																		:src="file.file_url"
																	>
																</video>
															</div>
														</figure>
													</div>
												</slide>
											</carousel>
										</div>
										<!-- 슬라이드 -->
									</div>
									<!-- //비주얼 영역 -->
									<!-- 텍스트 영역 -->
									<div
										v-if="item.vote_fg == 'Y'"
										class="text_view"
									>
										<button
											class="thumbnail_btn_s st_wjoin"

										>{{ $language.common.voting }}
										</button>
									</div>
									<div
										class="text_view"
									>
										<div class="text_area">
											<v-icon v-if="item.is_lock_body" class="size-px-16 mr-5">mdi-lock</v-icon>{{ item.contents | maxLength(65, '...') }}
										</div>
									</div>
									<!-- //텍스트 영역 -->
								</div>
								<!-- //게시글 메인 영역 -->
								<!-- 게시글 공감 댓글 -->
								<div class="post_count_view">
									<div class="post_count">
										<div class="postcountleft">
											{{ $language.common.sympathy }} <span>{{ item.sympaty_count }}</span>명
										</div>
										<div class="postcountright">
											{{ $language.common.comment }} <span>{{ item.comment_count }}</span>개
										</div>
									</div>
								</div>
								<!-- //게시글 공감 댓글 -->
							</article>
						</div>
					</div>
				</template>
				<Empty
					v-else

					class="size-px-16"
				></Empty>
			</div>
		</div>

		<popup_select
			v-if="is_select"
			@cancel="is_select = false"
		>
			<template
				v-slot:title
			>{{  $language.notice.choice }}</template>
			<template
				v-slot:list
			>
				<template
					v-for="(item, index) in list_board_config"
				>
					<li
						:key="'item_' + index"
						style="padding: 10px 0"
						@click="setBoard(item)"
					>{{ item.board_name }}</li>
				</template>
			</template>
		</popup_select>
	</div>
</template>
<script>

import { Carousel, Slide } from 'vue-carousel';
import popup_select from "@/view/Layout/PopupSelect";
import Empty from "@/view/Layout/Empty";
export default {
	name: 'CartelBoardCopy'
	, components: {Empty, popup_select, Carousel, Slide}
	, props: ['user', 'cartel_info', 'board_info']
	, data: function(){
		return {
			item_list: []
			, is_select: false
			, items_board_config: []
			, item_board_config: {}
			, items_bbs: []
			, item_search: {
				search_value: ''
				, page_number: 1
				, pagerecnum: 10
			}
		}
	}
	, computed: {
		text_title: function(){
			let t = '글 불러오기'

			return t
		}
		, text_board_name: function(){
			let t = ''

			t =  this.item_board_config.board_name

			return t
		}
		, list_board_config: function(){
			return this.item_list.filter( (item) => {
				return item
			})
		}
		, is_manager: function(){
			let t = false
			if(this.cartel_info.cartl_member_grade_chg_info.chg_cartl_member_div_code == 'CA02500001'){
				t = true
			}
			return t
		}
		, list_bbs: function(){

			return this.items_bbs.filter( (item) => {

				if(item.sympaty_icon_code != ''){
					item.is_empathy = true
				}
				item.time_t = this.$date.getTimeStory(item.registdate)

				if(item.post_file_list && item.post_file_list.length > 0){
					for(let i = 0; i < item.post_file_list.length; i++){
						let file = item.post_file_list[i]
						// item.post_file_list[i].file_url = item.post_file_list[i].post_appendix_file_partial_list[0].appendix_file_url
						file.file_url = file.post_appendix_file_org_url
						console.log(file.file_url)

						switch (file.appendix_file_div_code) {
							case 'CA01000001':
								file.is_image = true
								break;
							case 'CA01000002':
								file.is_video = true
								break;
							case 'CA01000003':
								file.is_audio = true
								break;
						}

						switch (file.post_appendix_file_div_code) {
							case 'CA01000001':
								file.is_image = true
								break;
							case 'CA01000002':
								file.is_video = true
								break;
							case 'CA01000003':
								file.is_audio = true
								break;
						}
					}
				}

				return item
			})
		}
	}
	, methods: {

		getCopyList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_board_copy_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, current_subscrp_board_number: this.board_info.board_number
					}
					, type: true
				})

				if(result.success){
					this.item_list = result.data.subscrp_board_list
					if(this.item_list.length > 0){
						this.item_board_config = this.item_list[0]
						console.log('this.item_board_config', this.item_board_config)
						await this.getBbsList()
					}
					// this.items = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBbsList: async function(){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_bbs_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel_info.car
						, board_number: this.item_board_config.board_number
						, srchtext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(this.items_bbs.length > 0){
						this.items_bbs = this.items_bbs.concat(result.data.post_list)
					}else{
						this.items_bbs = result.data.post_list
					}
					if(result.data.post_list.length > 0){
						setTimeout( () => {
							this.item_search.page_number++
							this.getBbsList()
						}, 500)
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onBoard: function(){
			this.is_select = true
		}
		, setBoard: function(item){
			this.is_select = false
			this.item_board_config = item
			this.items_bbs = []
			this.item_search.page_number = 1
			this.getBbsList()
		}
		, onMore: function(item){
			this.$emit('click', item)
		}
	}
	, created() {
		this.getCopyList()
	}
}
</script>